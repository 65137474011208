<template>
  <div>
    <h3>Påstand:</h3>
    <h2 class="paastander">Politisk korrekthet er viktigere enn akademisk ytringsfrihet.</h2> <!-- Expression/Question -->
    <vsa-list>
      <vsa-item>
        <vsa-heading>
          Les mer her
        </vsa-heading>

        <vsa-content>
          <div class="explaination">
            <p>
              I 2021 så ble Cecilie Hellestveit bedt om å «ligge lavt» og «forsøkt fjernet som foreleser». Dette var på
              grunn av en diskusjon om internasjonalisering der Hellestveit stilte seg kritisk til andel utalandske
              forskere i enkelte fag.
            </p>
            <p>
              Spørsmålet er da om politisk korrekthet er viktigere enn akademisk ytringsfrihet?
            </p>
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>
    <slot>></slot> <!-- Slot for react buttons -->
  </div>

</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: "Spm1",
  components:{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  }
}
</script>

<style scoped>

</style>